import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import css from "./MaterialCare.module.scss"
import HeaderNav from "../HeaderNav/HeaderNav"
import RichTextRender from "../../components/RichTextRender/RichTextRender"
import Img from "gatsby-image"

class MaterialCare extends Component {
  constructor(props) {
    super(props)

    this.state = {
      titleLoaded: false,
    }
  }
  render() {
    let preDescription = this.props.data.allContentfulMaterialGuide.edges[0].node.preDescription.json
    let juxtaposeList = this.props.data.allContentfulMaterialGuide.edges[0].node.juxtaposeList
    return (
      <div className={css.container}>
        <div className={this.state.titleLoaded ? css.navHeaderContainerTransition : css.navHeaderContainer}>
          <HeaderNav titleLoaded={true} backgroundWhiteColor={true}></HeaderNav>
        </div>
        <div className={css.pageWrapper}>
          {preDescription && <RichTextRender documentRichText={preDescription}></RichTextRender>}
          <div className={css.ListWrapper}>
            {juxtaposeList.map((juxtaposeItem, juxtaposeItemIndex) => {
              return (
                <div key={"juxtaposeList" + juxtaposeItemIndex} className={css.rowBlock}>
                  {true && <div className={css.lineDivider}></div>}
                  {juxtaposeItem.blockList.map(blockItem => {
                    let isImage = blockItem.image ? true : false
                    return (
                      <div key={"blockItem" + blockItem.id} className={isImage ? css.isImage : css.isText}>
                        {blockItem.image && <Img sizes={blockItem.image.sizes} className={css.gatsbyImageWrapper} />}
                        {/* <Image src={blockItem.image.file.url}></Image> */}
                        {blockItem.richTextField && (
                          <div>
                            <RichTextRender documentRichText={blockItem.richTextField.json}></RichTextRender>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulMaterialGuide {
          edges {
            node {
              juxtaposeList {
                id
                blockList {
                  ... on ContentfulRichTextBlock {
                    id
                    name
                    richTextField {
                      json
                    }
                  }
                  ... on ContentfulImage {
                    id
                    name
                    image {
                      sizes(maxWidth: 1080) {
                        ...GatsbyContentfulSizes
                      }
                      file {
                        url
                      }
                    }
                  }
                }
              }
              preDescription {
                json
              }
            }
          }
        }
      }
    `}
    render={data => <MaterialCare data={data} {...props} />}
  />
)
