import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MaterialCareContainer from "../containers/MaterialCare/MaterialCare";
// import Image from "../components/image"
// import Test from "../components/Test/test"

const MaterialCare = () => (
  <Layout>
    <SEO title="MaterialCare" />
    <MaterialCareContainer></MaterialCareContainer>
  </Layout>
)

export default MaterialCare
